import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"


// markup
const NotFoundPage = () => {
  return (
    <Layout>
    <main style={{display: "flex", flexFlow: "column", alignItems: "center"}}>
      <h2 style={{marginTop: "2rem"}}>Page not found</h2>
      <Link style={{marginTop: "1rem", color: "#bba040"}} to="/">Go home</Link>
    </main>
  </Layout>
  
  )
}

export default NotFoundPage
